<template>
  <div class="shelters">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate style="background: #39416f" :title="title.titlePage" :subtitle="title.subtitlePage" :img="title.img"/>
    <InformationsTemplate :card="abrigos" />
    <InformationsTemplate :card="casa" />
    <InformationsTemplate :card="cras" />
    <InformationsTemplate :card="creas" />
    <InformationsTemplate :card="defesa" />
    <InformationsTemplate :card="republicas" />
    <p>
      <router-link :to="{ name: 'refugees-menu-PT' }">{{
        $t("shelters.linkName")
      }}</router-link>
    </p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy
  },
  data() {
    return {
      title: {
        titlePage: this.$t("shelters.titlePage"),
        subtitlePage: this.$t("shelters.subtitlePage"),
        img: "shelters.png"
      },
      abrigos: {
        title: this.$t("shelters.abrigosTitle"),
        text: this.$t("shelters.abrigosText"),
        link:
          "https://www.google.com.br/maps/search/abrigos+no+rio+de+janeiro/@-22.7044371,-43.8417537,10z/data=!3m1!4b1",
        linkName: this.$t("shelters.linkName2"),
      },
      republicas: {
        title: this.$t("shelters.republicasTitle"),
        text: this.$t("shelters.republicasText"),
        link:
          "https://www.google.com.br/maps/search/republicas+no+rio+de+janeiro/@-22.7061921,-43.8417505,10z/data=!3m1!4b1",
        linkName: this.$t("shelters.linkName2"),
      },
      defesa: {
        title: this.$t("shelters.defesaTitle"),
        text: this.$t("shelters.defesaText"),
        link:
          "https://www.google.com.br/maps/search/defesa+civil+rio+de+janeito/@-22.85334,-43.3460273,12z/data=!3m1!4b1",
        linkName: this.$t("shelters.linkName2"),
      },
      casa: {
        title: this.$t("shelters.casaTitle"),
        text: this.$t("shelters.casaText"),
        link:
          "https://www.google.com.br/maps/search/minha+casa+minha+vida+rio+de+janeiro/@-22.8526459,-43.3460277,12z/data=!3m1!4b1",
        linkName: this.$t("shelters.linkName2"),
      },
      creas: {
        title: this.$t("shelters.creasTitle"),
        text: this.$t("shelters.creasText"),
        link:
          "https://www.1746.rio/portal/servicos/informacao?conteudo=989",
        linkName: this.$t("shelters.creasLinkName"),
      },
      cras :{
        title: this.$t("legal.crasTitle"),
        text: this.$t("legal.crasText"),  
        link: "https://carioca.rio/servicos/cras-centro-de-referencia-de-assistencia-social/",
        linkName: this.$t("legal.crasLinkName")
      }
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0 0 50px 120px;
}
</style>